import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import UploadFiles from "./components/UploadFiles";
import Status from "./components/Status";

const App = () => {
  const [currentTab, setCurrentTab] = useState("/");

  return (
    <Router>
      <div>
        {/* Pass setCurrentTab to Navbar */}
        <Navbar setCurrentTab={setCurrentTab} />
        <Routes>
          <Route path="/" element={<UploadFiles />} />
          <Route path="/status" element={<Status />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
