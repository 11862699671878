import React from "react";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";

const Navbar = ({ setCurrentTab }) => {
  const navigate = useNavigate();

  const handleTabChange = (tab) => {
    setCurrentTab(tab); // Update the state
    navigate(tab); // Navigate to the selected route
  };

  return (
    <nav className="navbar">
      <button onClick={() => handleTabChange("/")} className="nav-button">
        Upload
      </button>
      <button onClick={() => handleTabChange("/status")} className="nav-button">
        Status
      </button>
    </nav>
  );
};

export default Navbar;
