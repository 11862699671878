import axios from "axios";

// Base URL for the API
export const API_URL = "http://localhost:5000";

// Create an Axios instance
const api = axios.create({
  baseURL: API_URL,
  timeout: 30000, // 30 seconds timeout
  headers: {
    "Content-Type": "application/json",
  },
});

// Add request interceptor for Authorization header
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Assuming token is stored in localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add response interceptor for handling errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.error("Response error:", error.response.data);
    } else if (error.request) {
      console.error("Request error:", error.request);
    } else {
      console.error("Error:", error.message);
    }
    return Promise.reject(error);
  }
);

// API endpoint functions
export const getStatus = () => api.get("/status"); // Fetch scraping status
export const getDocuments = () => api.get("/documents"); // Fetch documents
export const getPendingTasksCount = () => api.get("/pending_tasks"); // Fetch pending tasks count
export const getPendingTasksDetails = () => api.get("/debug_pending_tasks"); // Fetch detailed pending tasks

export const addScrapeTask = (urls) => api.post("/add_scrape_task", { urls }); // Add scraping task
export const addPdfTask = (pdfFiles) => {
  const formData = new FormData();
  pdfFiles.forEach((file) => formData.append("file", file));
  return api.post("/add_pdf_task", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getDocumentsWithTimestamps = () => api.get("/documents_with_timestamps"); // Fetch documents with timestamps

export default api;
