import React, { useEffect, useState } from "react";
import { getStatus, getDocuments } from "../api"; // Use reusable API functions
import "./Status.css";

const Status = () => {
  const [status, setStatus] = useState({
    total_pending_tasks: 0,
    total_urls: 0,
    total_pdfs: 0,
    total_words: 0,
    total_lines: 0,
  });
  const [documents, setDocuments] = useState([]);
  const [expandedIndices, setExpandedIndices] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 5;

  // Fetch general status statistics
  const fetchStatusData = async () => {
    try {
      const response = await getStatus();
      setStatus({
        total_pending_tasks: response.data.total_pending_tasks || 0,
        total_urls: response.data.total_urls || 0,
        total_pdfs: response.data.total_pdfs || 0,
        total_words: response.data.total_words || 0,
        total_lines: response.data.total_lines || 0,
      });
    } catch (err) {
      console.error("Error fetching status:", err);
      setError("Failed to fetch status data.");
    }
  };

  // Fetch documents
  const fetchDocumentsData = async (initialLoad = false) => {
    if (initialLoad) setIsLoading(true);
    try {
      const response = await getDocuments();
      setDocuments(response.data.documents || []);
    } catch (err) {
      console.error("Error fetching documents:", err);
      setError("Failed to fetch documents.");
    } finally {
      if (initialLoad) setIsLoading(false);
    }
  };

  useEffect(() => {
    // Initial fetch
    fetchStatusData();
    fetchDocumentsData(true);

    // Poll data every 5 seconds
    const interval = setInterval(() => {
      fetchStatusData();
      fetchDocumentsData();
    }, 5000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const toggleDocument = (index) => {
    setExpandedIndices((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Pagination helpers
  const totalPages = Math.ceil(documents.length / itemsPerPage);
  const paginatedDocuments = documents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const goToPreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const goToNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  return (
    <div className="status-container">
      <h3 className="status-heading">Scraping Status</h3>

      {/* Error Display */}
      {error && <div className="error-message">{error}</div>}

      {/* Status Overview */}
      <div className="status-row">
        <div className="status-box">
          <strong>Pending:</strong> {status.total_pending_tasks}
        </div>
        <div className="status-box">
          <strong>Total URLs:</strong> {status.total_urls}
        </div>
        <div className="status-box">
          <strong>Total PDFs:</strong> {status.total_pdfs}
        </div>
        <div className="status-box">
          <strong>Total Words:</strong> {status.total_words}
        </div>
        <div className="status-box">
          <strong>Total Lines:</strong> {status.total_lines}
        </div>
      </div>

      {/* Document View */}
      <div className="documents-view">
        <h4>Document View</h4>
        {isLoading ? (
          <div className="loading-spin"></div>
        ) : documents.length === 0 ? (
          <p>No documents available to show</p>
        ) : (
          <>
            <ul className="documents-list">
              {paginatedDocuments.map((doc, index) => (
                <li key={index} className="document-item">
                  <strong>Title:</strong> {doc.title || "N/A"}
                  <div
                    className={`document-preview ${
                      expandedIndices[index] ? "expanded" : "collapsed"
                    }`}
                  >
                    {expandedIndices[index]
                      ? doc.content.join("\n") || "No content available"
                      : doc.content.slice(0, 3).join("\n") || "No content available"}
                  </div>
                  <button
                    className="toggle-button"
                    onClick={() => toggleDocument(index)}
                  >
                    {expandedIndices[index] ? "Show Less" : "Show More"}
                  </button>
                </li>
              ))}
            </ul>

            {/* Pagination Controls */}
            <div className="pagination-control">
              <button
                className={`pagination-button ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                onClick={goToPreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="pagination-texts">
                Page {currentPage} of {totalPages}
              </span>
              <button
                className={`pagination-button ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
                onClick={goToNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Status;
