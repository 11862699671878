import React, { useState, useEffect } from "react";
import {
  addScrapeTask,
  addPdfTask,
  getDocumentsWithTimestamps,
  getPendingTasksDetails,
} from "../api"; // Import API functions
import "./UploadFiles.css";

function App() {
  const [urls, setUrls] = useState("");
  const [pdfFiles, setPdfFiles] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [modalMessage, setModalMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [completedDocuments, setCompletedDocuments] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({ current: 0, total: 0, percentage: 0 });

  // Pagination states for Completed tasks
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Validate URL
  const isValidUrl = (url) => {
    const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\/\w-]*)*$/;
    return urlRegex.test(url);
  };

  // File handlers
  const handleFileChange = (e) => {
    setPdfFiles([...pdfFiles, ...e.target.files]);
  };

  const removeFile = (index) => {
    setPdfFiles(pdfFiles.filter((_, i) => i !== index));
  };

  const removeAllFiles = () => {
    setPdfFiles([]);
  };

  // Modal handlers
  const openModal = (message) => {
    setModalMessage(message);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalMessage("");
    setIsModalOpen(false);
  };

  // Submit files and URLs
  const submitFiles = async () => {
    const urlRequests = urls
      .split("\n")
      .map((url) => url.trim())
      .filter((url) => url && isValidUrl(url));

    const totalDocuments = urlRequests.length + pdfFiles.length;

    if (totalDocuments === 0) {
      openModal("Please provide at least one valid URL or attach a PDF.");
      return;
    }

    setUploadProgress({ current: 0, total: totalDocuments, percentage: 0 });
    setIsUploading(true);

    const updateProgress = () => {
      setUploadProgress((prev) => ({
        current: prev.current + 1,
        total: prev.total,
        percentage: Math.round(((prev.current + 1) / prev.total) * 100),
      }));
    };

    const warnings = [];

    try {
      // Submit URLs
      if (urlRequests.length > 0) {
        const response = await addScrapeTask(urlRequests);
        warnings.push(...(response.data.warnings || []));
        response.data.task_ids.forEach(() => updateProgress());
      }

      // Submit PDF files
      if (pdfFiles.length > 0) {
        for (const file of pdfFiles) {
          const response = await addPdfTask([file]);
          warnings.push(...(response.data.warnings || []));
          response.data.task_ids.forEach(() => updateProgress());
        }
      }

      if (warnings.length > 0) {
        openModal(`Warnings:\n${warnings.join("\n")}`);
      } else {
        openModal("Files and URLs submitted successfully!");
      }

      setUrls("");
      setPdfFiles([]);
    } catch (error) {
      openModal("An error occurred while submitting files. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  // Fetch task status
  const fetchTaskStatus = async () => {
    try {
      const response = await getPendingTasksDetails();
      setTasks(response.data.tasks || []);
    } catch (error) {
      console.error("Error fetching task status:", error.message);
    }
  };

  // Fetch completed documents
  const fetchCompletedDocuments = async () => {
    try {
      const response = await getDocumentsWithTimestamps();
      setCompletedDocuments(response.data.documents || []);
    } catch (error) {
      console.error("Error fetching completed documents:", error.message);
    }
  };

  useEffect(() => {
    fetchTaskStatus();
    fetchCompletedDocuments();
    const interval = setInterval(() => {
      fetchTaskStatus();
      fetchCompletedDocuments();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  // Helpers
  const getTasksByStatus = (status) => tasks.filter((task) => task.status === status);

  const totalCompletedPages = Math.ceil(completedDocuments.length / itemsPerPage);

  const paginatedCompletedDocuments = completedDocuments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const goToPreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const goToNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalCompletedPages));

  return (
    <div className="upload-container">
      <div className="content">
        <div className="left-section">
          <h3>Save URL and Upload Doc/PDF</h3>
          <div style={{ position: "relative" }}>
            <textarea
              value={urls}
              onChange={(e) => setUrls(e.target.value)}
              placeholder="Enter URLs (one per line)"
              className="textarea"
            />
            <label htmlFor="file-upload" className="attach-icon">
              &#x1F4C4; {/* Attach Icon */}
            </label>
          </div>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            accept="application/pdf"
            style={{ display: "none" }}
            id="file-upload"
          />
          {pdfFiles.length > 0 && (
            <div className="file-list-container">
              <div className="file-list">
                {pdfFiles.map((file, index) => (
                  <div key={index} className="file-item">
                    <span className="file-name">{file.name}</span>
                    <button className="remove-file" onClick={() => removeFile(index)}>
                      &#x2716;
                    </button>
                  </div>
                ))}
              </div>
              <button className="remove-all" onClick={removeAllFiles}>
                Remove All
              </button>
            </div>
          )}
          <button onClick={submitFiles} className="submit-button">
            <h2>Submit</h2>
          </button>
          {isUploading && (
            <div className="uploading-status">
              <div className="loading-spinner"></div>
              <p>
                Uploading {uploadProgress.percentage}% ({uploadProgress.current} / {uploadProgress.total})
              </p>
            </div>
          )}
        </div>

        <div className="right-section">
          <h3>Process History</h3>
          <div className="process-section">
            <h4>Inqueue</h4>
            <ul className="history-list">
              {getTasksByStatus("pending").length > 0 ? (
                getTasksByStatus("pending").map((task, index) => (
                  <li key={index}>{task.data || task.filename}</li>
                ))
              ) : (
                <li>!!!Empty!!!</li>
              )}
            </ul>
          </div>
          <div className="process-section">
            <h4>Processing</h4>
            <ul className="history-list">
              {getTasksByStatus("processing").length > 0 ? (
                getTasksByStatus("processing").map((task, index) => (
                  <li key={index}>{task.data || task.filename}</li>
                ))
              ) : (
                <li>!!!Nothing to Process!!!</li>
              )}
            </ul>
          </div>
          <div className="process-section">
            <h4>Completed</h4>
            <ul className="history-list">
              {paginatedCompletedDocuments.length > 0 ? (
                paginatedCompletedDocuments.map((doc, index) => (
                  <li key={index}>
                    {doc.filename || doc.url || "Unknown Document"} -{" "}
                    <span className="timestamp">{doc.scraped_at || doc.uploaded_at}</span>
                  </li>
                ))
              ) : (
                <li>!!!No documents to Show!!!</li>
              )}
            </ul>
            {completedDocuments.length > itemsPerPage && (
               <div className="pagination-controls">
               <button 
                 onClick={goToPreviousPage} 
                 disabled={currentPage === 1} 
                 className={`pagination-buttons ${currentPage === 1 ? 'disabled' : ''}`}
               >
                 Previous
               </button>
               <span className="pagination-text">
                 Page {currentPage} of {totalCompletedPages}
               </span>
               <button 
                 onClick={goToNextPage} 
                 disabled={currentPage === totalCompletedPages} 
                 className={`pagination-buttons ${currentPage === totalCompletedPages ? 'disabled' : ''}`}
               >
                 Next
               </button>
             </div>
            
             )}
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>
            <p>{modalMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;